import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import './scss/style.scss'
import ReactGA from 'react-ga4'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const StaticPage = React.lazy(() => import('./views/pages/static/StaticPage.js'))
const Laura = React.lazy(() => import('./views/pages/static/MockTrainer.js'))
const Trainer = React.lazy(() => import('./views/pages/static/Trainer.js'))
const Workout = React.lazy(() => import('./views/pages/static/Workout.js'))
const Workouts = React.lazy(() => import('./views/pages/static/Workouts.js'))
const TrainerLandingPage = React.lazy(() => import('./views/pages/TrainerLandingPage.js'))
const PtOnboarding = React.lazy(() => import('./views/pages/PtOnboarding.js'))

class App extends Component {
  componentDidMount() {
    ReactGA.initialize('G-LD01RE1FJQ')
    this.logPageView(this.props.location.pathname)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      console.log('Route changed to:', this.props.location.pathname)
      this.logPageView(this.props.location.pathname)
    }
  }

  logPageView(page) {
    ReactGA.send({ hitType: 'pageview', page: page })
  }

  render() {
    return (
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route exact path="/land" name="Land" component={StaticPage} />
          <Route exact path="/laura" name="Laura" component={Laura} />
          <Route exact path="/trainer/:id" name="Trainer" component={Trainer} />
          <Route exact path="/trainer" name="Trainer Landing" component={TrainerLandingPage} />
          <Route
            exact
            path="/pt-onboard"
            name="Personal Trainer Onboarding"
            component={PtOnboarding}
          />
          <Route exact path="/workout/:id" name="Land" component={Workout} />
          <Route exact path="/workouts" name="Land" component={Workouts} />
          <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
        </Switch>
      </React.Suspense>
    )
  }
}

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
}

export default withRouter(App)
